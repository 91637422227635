/* eslint-disable react-hooks/exhaustive-deps */
import db from './firebase';
import './App.css';
import './assets/fonts/fonts.css';
import './styles/InputTable.scss';
import './styles/Nav.scss';
import './styles/Option.scss';
import './styles/Word.scss';
import './styles/Board.scss';
import { useEffect, lazy, Suspense } from 'react';
import CompetitionGame from './pages/teacher/competition/game';
import Schedule from './pages/teacher/schedule/main';
import ScheduleInput from './pages/teacher/input/schedule';
import ScheduleUpdate from './pages/teacher/schedule/update';
import QuizInput from './pages/teacher/input/quiz';
import QuestionUpload from './pages/teacher/upload/question';
import Stopwatch from './Component/Stopwatch';
import StudentStat from './pages/teacher/student-stat';
import StudentList from './pages/teacher/student-list';
import WordPage from './pages/word';
import { Route, Routes } from 'react-router-dom';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import ManageUser from './pages/teacher/manage-user';
import MyPage from './pages/my-page';
import ReviewNote from './pages/teacher/review-note';
import { useDispatch, useSelector } from 'react-redux';
import { dateControl } from './Methods';
import { getOfficeData } from './redux/FirebaseData/Office/reducer';
import { getClassesData } from './redux/FirebaseData/Classes/reducer';
import { getScheduleListData } from './redux/FirebaseData/ScheduleList/reducer';
import { getScheduleData } from './redux/FirebaseData/Schedule/reducer';
import { getCarouselData } from './redux/FirebaseData/Carousel/reducer';
import { getPresetStudentData } from './redux/FirebaseData/PresetStudent/reducer';
import { getUsersData } from './redux/FirebaseData/Users/reducer';
import LoadingPage from './Component/PageComponent/LoadingPage';
import HomeCarousel from './pages/teacher/home';
import PopupModal from './Component/PageComponent/PopupModal';
import DisplayCards from './pages/teacher/display-card';
import Reading from './pages/reading/index.jsx';
import { getPassageData } from './redux/FirebaseData/Passage/reducer';
import UploadPassage from './pages/teacher/upload/passage';
import TestModify from './pages/teacher/modify/score';
import CompetitionModify from './pages/teacher/competition/modify';
import { getQIDPartData } from './redux/FirebaseData/QIDPart/reducer';
import QuestionModify from './pages/teacher/modify/question';
import UploadWord from './pages/teacher/upload/word';
import ModifyWord from './pages/teacher/modify/word';
import ModifyPassage from './pages/teacher/modify/passage';
import { getSettingData } from './redux/FirebaseData/Setting/reducer';
import Setting from './pages/admin/setting';
import AdminPayment from './pages/admin/payment';
import { redirectGoogle, useAuth } from './api/authApi';
import { setToggle } from './redux/toggleState/reducer';
import { getOption } from './api/optionApi';
import DashBoard from './pages/dashboard';
import WordGame from './pages/word-game/play';
import SelectQuiz from './pages/word-game/main';
import QuizResult from './pages/quiz/result';
import DailyQuizResult from './pages/teacher/quiz/daily-result';
import AdminTodo from './pages/admin/todo';
import AdminTodoCheck from './pages/admin/todo-check';
import SelectTimeWord from './pages/time-word/main';
import TimeWordPage from './pages/time-word/play';
import { LoadingIndicator } from 'components/molecules';
import LoginPage from 'pages/login';
import SignUpPage from 'pages/signup';
import FindPasswordPage from 'pages/find-password';
import MainPage from 'pages/main';
import Page404 from 'pages/404';
import NotAllowedPage from 'pages/not-allowed';
import RefactoredTestInput from 'pages/teacher/input/score/refactored_version_page';
import { ToastContainer } from 'react-toastify';

let Competition = lazy(() => import('./pages/teacher/competition/main'));

function App() {
  // redux dispatch
  const dispatch = useDispatch();

  // redux reducers
  const currentUser = useSelector((state) => state.currentUserReducer).accountInfo;
  const myInfo = useSelector((state) => state.currentUserReducer).storeInfo; // 사용자 정보
  const loading = useSelector((state) => state.toggleStateReducer).loading.main; // main loading state
  const downloadControler = useSelector((state) => state.downloadControlerReducer);

  // firebase raw user data
  // const currentUser = useAuth();

  // 구글 로그인시 구글 로그인 페이지로 redirect 하는 함수
  const redirectGoogleSignUp = async () => {
    dispatch(setToggle({ loading: 'main' }, true)); // loading state true로 변경
    try {
      await redirectGoogle(); // google login page로 redirect
    } catch (error) {
      alert('Error!\n' + error.message); // 오류시 alert
    }
  };

  useAuth();

  // 웹 페이지 실행 시 구글 로그인 redirect 함수 실행
  useEffect(() => {
    redirectGoogleSignUp();
  }, []);

  useEffect(() => {
    // 2초 후 loading state false로 변경
    setTimeout(() => {
      loading && dispatch(setToggle({ loading: 'main' }, false));
    }, 2000);
  }, [loading]);

  // myInfo 변경 시 fireStore 정보 받아와서 전역으로 설정
  useEffect(() => {
    // 해당 지점 대문 텍스트 데이터 query
    const carouselCollection =
      myInfo.office &&
      query(
        collection(db, 'Carousel'),
        where('office', '==', myInfo.office),
        orderBy('number', 'asc'),
      );
    // 선생님일 경우 학생 프리셋 데이터 query
    const studentSettingCollection =
      myInfo.teacher &&
      query(collection(db, 'Users', myInfo.uid, 'studentSetting'), orderBy('title', 'asc'));
    // 선생님일 경우 학생 프리셋 데이터 query
    const settingCollection = getOption(myInfo);
    // 스케쥴 데이터 query
    const scheduleCollection =
      myInfo.teacher !== undefined && myInfo.teacher !== null && myInfo.teacher
        ? myInfo.office &&
          query(
            collection(db, 'Schedule', myInfo.office, 'Plan'),
            where('date', '>=', dateControl(-30)),
            where('date', '<=', dateControl(40)),
            orderBy('date', 'asc'),
            orderBy('cid', 'asc'),
            orderBy('subject', 'asc'),
          ) // 선생님일 경우 해당 지점 전체 스케쥴 받아오기
        : myInfo.office &&
          query(
            collection(db, 'Schedule', myInfo.office, 'Plan'),
            where('date', '>=', dateControl(-1)),
            where('date', '<=', dateControl(7)),
            where('subject', '==', 'Competition'),
            orderBy('date', 'asc'),
          ); // 선생님이 아닐 경우 해당 지점 일주일 스케쥴 받아오기

    dispatch(setToggle({ loading: 'main' }, true)); // loading state true로 변경
    dispatch(getOfficeData()); // 지점 데이터 전역으로 넘기기
    dispatch(getClassesData()); // 반 데이터 전역으로 넘기기
    dispatch(getScheduleListData(myInfo.office)); // 해당 지점 스케쥴 레이블 전역으로 넘기기
    // currentUser 정보와 선생님이고 사용 허가를 받은 사용자의 경우만 전체 사용자 데이터 전역으로 넘기기
    if (currentUser && myInfo.teacher === true && myInfo.allow === true) {
      dispatch(getUsersData(myInfo.office));
    }
    // 해당 지점 대문 데이터 전역으로 넘기기
    carouselCollection &&
      onSnapshot(carouselCollection, (snapshot) => {
        dispatch(getCarouselData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))));
      });
    // 학셍 프리셋 데이터 전역으로 넘기기
    studentSettingCollection &&
      onSnapshot(studentSettingCollection, (snapshot) => {
        dispatch(getPresetStudentData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))));
      });
    // 학셍 프리셋 데이터 전역으로 넘기기
    settingCollection &&
      onSnapshot(settingCollection, (doc) => {
        dispatch(getSettingData({ ...doc.data(), id: myInfo.office }));
      });

    // 위에서 설정한 기준에 따라 스케쥴 데이터 다운로드 후 전역으로 넘기기
    scheduleCollection &&
      onSnapshot(scheduleCollection, (snapshot) => {
        dispatch(getScheduleData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))));
      });
  }, [myInfo]);

  useEffect(() => {
    downloadControler.qidPart && dispatch(getQIDPartData()); // QID 데이터 전역으로 넘기기
  }, [downloadControler.qidPart]);

  useEffect(() => {
    downloadControler.passage && dispatch(getPassageData()); // 본문 데이터 전역으로 넘기기
  }, [downloadControler.passage]);

  // useEffect(() => {
  //   // 전체 사용자 query
  //   const userCollection = query(
  //     collection(db, "Users"),
  //     orderBy("name", "asc")
  //   );
  //   // 전체 사용자 데이터 전역으로 넘기기
  //   if (currentUser && myInfo.teacher === true && myInfo.allow === true && downloadControler.users) {
  //     // currentUser 정보와 선생님이고 사용 허가를 받은 사용자의 경우만 전체 사용자 데이터 전역으로 넘기기
  //     onSnapshot(userCollection, (snapshot) => {
  //       dispatch(
  //         getUsersData(
  //           snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  //         )
  //       );
  //     });
  //   }
  // }, [downloadControler.users]);

  useEffect(() => {
    // 스케쥴 데이터 query
    const scheduleCollection =
      myInfo.teacher !== undefined && myInfo.teacher !== null && myInfo.teacher
        ? myInfo.office &&
          query(
            collection(db, 'Schedule', myInfo.office, 'Plan'),
            where('date', '>=', dateControl(-30)),
            where('date', '<=', dateControl(40)),
            orderBy('date', 'asc'),
            orderBy('cid', 'asc'),
            orderBy('subject', 'asc'),
          ) // 선생님일 경우 해당 지점 전체 스케쥴 받아오기
        : myInfo.office &&
          query(
            collection(db, 'Schedule', myInfo.office, 'Plan'),
            where('date', '>=', dateControl(-1)),
            where('date', '<=', dateControl(7)),
            where('subject', '==', 'Competition'),
            orderBy('date', 'asc'),
          ); // 선생님이 아닐 경우 해당 지점 일주일 스케쥴 받아오기
    // 위에서 설정한 기준에 따라 스케쥴 데이터 다운로드 후 전역으로 넘기기
    scheduleCollection &&
      onSnapshot(scheduleCollection, (snapshot) => {
        dispatch(getScheduleData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))));
      });
  }, []);

  // useEffect(() => {
  //   // 선생님일 경우 학생 프리셋 데이터 query
  //   const studentSettingCollection =
  //     myInfo.teacher &&
  //     query(
  //       collection(db, "Users", myInfo.uid, "studentSetting"),
  //       orderBy("title", "asc")
  //     );
  //   // 학생 프리셋 데이터 전역으로 넘기기
  //   downloadControler.presetStudent && studentSettingCollection &&
  //     onSnapshot(studentSettingCollection, (snapshot) => {
  //       dispatch(
  //         getPresetStudentData(
  //           snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  //         )
  //       );
  //     });
  // }, [downloadControler.presetStudent]);

  // useEffect(() => {
  //   dispatch(getScheduleListData(myInfo.office)); // 해당 지점 스케쥴 레이블 전역으로 넘기기
  // }, [downloadControler.scheduleList])

  // useEffect(() => {
  //   dispatch(getClassesData()); // 반 데이터 전역으로 넘기기
  // }, [downloadControler.classes]);

  return loading ? (
    <LoadingPage />
  ) : (
    <div className="App">
      <LoadingIndicator />
      <PopupModal />
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/learningcenter" element={<LoginPage pageType="student" />} />
        <Route exact path="/learningcenter/signup" element={<SignUpPage pageType="student" />} />
        <Route
          exact
          path="/learningcenter/findpassword"
          element={<FindPasswordPage pageType="student" />}
        />
        <Route exact path="/learningcenter/home" element={<DashBoard pageType="student" />} />
        <Route
          exact
          path="/learningcenter/notallow"
          element={<NotAllowedPage pageType="student" />}
        />
        <Route exact path="/learningcenter/voca" element={<WordPage pageType="student" />} />
        <Route exact path="/learningcenter/reading" element={<Reading pageType="student" />} />
        <Route exact path="/learningcenter/mypage" element={<MyPage pageType="student" />} />
        {/* <Route exact path="/learningcenter/board/notice" element={<BoardNotice pageType="student" />} /> */}
        <Route exact path="/learningcenter/wordgame/main" element={<SelectQuiz />} />
        <Route exact path="/learningcenter/wordgame/play" element={<WordGame />} />
        <Route exact path="/learningcenter/wordgame/result" element={<QuizResult />} />
        <Route exact path="/learningcenter/timeword/main" element={<SelectTimeWord />} />
        <Route exact path="/learningcenter/timeword/play" element={<TimeWordPage />} />

        <Route exact path="/teachingcenter" element={<LoginPage pageType="teacher" />} />
        <Route exact path="/teachingcenter/signup" element={<SignUpPage pageType="teacher" />} />
        <Route
          exact
          path="/teachingcenter/findpassword"
          element={<FindPasswordPage pageType="teacher" />}
        />
        <Route exact path="/teachingcenter/home" element={<HomeCarousel pageType="teacher" />} />
        <Route
          exact
          path="/teachingcenter/notallow"
          element={<NotAllowedPage pageType="teacher" />}
        />
        <Route exact path="/teachingcenter/students/list" element={<StudentList />} />
        <Route exact path="/teachingcenter/students" element={<StudentStat />} />
        <Route
          exact
          path="/teachingcenter/competition"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Competition />
            </Suspense>
          }
        ></Route>
        <Route exact path="/teachingcenter/system" element={<ManageUser />}></Route>
        <Route exact path="/teachingcenter/competition/game" element={<CompetitionGame />} />
        <Route exact path="/teachingcenter/competition/modify" element={<CompetitionModify />} />
        <Route exact path="/teachingcenter/test/input" element={<RefactoredTestInput />} />
        <Route exact path="/teachingcenter/test/modify" element={<TestModify />} />
        <Route exact path="/teachingcenter/quiz/input" element={<QuizInput />} />
        <Route exact path="/teachingcenter/quiz/reviewnote" element={<ReviewNote />} />
        <Route exact path="/teachingcenter/test/reading" element={<Reading pageType="teacher" />} />
        <Route exact path="/teachingcenter/schedule" element={<Schedule />} />
        <Route exact path="/teachingcenter/schedule/input" element={<ScheduleInput />} />
        <Route exact path="/teachingcenter/schedule/update" element={<ScheduleUpdate />} />
        {/* <Route exact path="/teachingcenter/board/notice" element={<BoardNotice pageType="teacher" />} />
        <Route exact path="/teachingcenter/board/bpm" element={<BoardBPM />} />
        <Route exact path="/teachingcenter/board/bpm/write" element={<BoardBPMWrite />} />
        <Route exact path="/teachingcenter/board/bpm/content/:contentId" element={<BoardBPMContent />} /> */}
        <Route
          exact
          path="/teachingcenter/tool"
          element={<DisplayCards displayType="teacherTools" title="Tool" pageType="teacher" />}
        />
        <Route exact path="/teachingcenter/tool/stopwatch" element={<Stopwatch />} />
        <Route exact path="/teachingcenter/tool/voca" element={<WordPage pageType="teacher" />} />
        <Route exact path="/teachingcenter/tool/todo" element={<AdminTodo />} />
        <Route exact path="/teachingcenter/tool/todocheck" element={<AdminTodoCheck />} />
        <Route exact path="/teachingcenter/mypage" element={<MyPage pageType="teacher" />} />
        <Route
          exact
          path="/teachingcenter/admin"
          element={<DisplayCards displayType="adminTools" title="Admin" pageType="teacher" />}
        />
        <Route exact path="/teachingcenter/admin/uploadquestion" element={<QuestionUpload />} />
        <Route exact path="/teachingcenter/admin/modifyquestion" element={<QuestionModify />} />
        <Route exact path="/teachingcenter/admin/uploadpassage" element={<UploadPassage />} />
        <Route exact path="/teachingcenter/admin/modifypassage" element={<ModifyPassage />} />
        <Route exact path="/teachingcenter/admin/uploadword" element={<UploadWord />} />
        <Route exact path="/teachingcenter/admin/modifyword" element={<ModifyWord />} />
        <Route exact path="/teachingcenter/admin/dailyquizresult" element={<DailyQuizResult />} />
        <Route exact path="/teachingcenter/admin/payment" element={<AdminPayment />} />
        <Route exact path="/teachingcenter/admin/setting" element={<Setting />} />
        <Route exact path="/teachingcenter/admin/todo" element={<AdminTodo />} />
        <Route exact path="/teachingcenter/admin/todocheck" element={<AdminTodoCheck />} />
        <Route path="/*" element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default App;
