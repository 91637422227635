import { useCallback, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { GrowingClass } from 'types/class';
import { getGrowingClasses } from '.';

export default function useGetGrowingClasses() {
  const [growingClasses, setGrowingClasses] = useState<GrowingClass[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userUid = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo
    .uid as string;

  const getClassesInfo = useCallback(async () => {
    if (!userUid) {
      return;
    }
    setIsLoading(true);

    try {
      const growingClassesInfo = await getGrowingClasses();
      setGrowingClasses(growingClassesInfo);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, [userUid]);

  useEffect(() => {
    getClassesInfo();
  }, [getClassesInfo]);

  return { data: growingClasses, isLoading };
}
