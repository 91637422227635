import { Dialog } from 'components/molecules';

interface ConfirmDeletePopupProps {
  onClose: () => void;
  onDelete: () => void;
}

export const ConfirmDeletePopup = ({ onClose, onDelete }: ConfirmDeletePopupProps) => {
  return (
    <Dialog
      title="정말로 삭제하시겠습니까?"
      onClose={onClose}
      buttons={[
        { children: '취소', variant: 'outline', onClick: onClose },
        { children: '삭제하기', variant: 'danger-outline', onClick: onDelete },
      ]}
    />
  );
};
