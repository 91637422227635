import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;

  gap: 48px;
`;

export const StudentsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  width: 100%;
`;

export const StudentsPresetButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1;
  padding: 0 12px;

  align-items: center;

  width: 100%;
`;

export const AddScoreButton = styled.button`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  border-radius: 50%;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScoreSetContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  gap: 12px;
  width: 100%;

  & > div {
    width: 100%;
  }
`;

export const RemoveScoreButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0 0 12px 0;
`;
