import db from '../../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { GrowingClass } from 'types/class';

export async function getGrowingClasses() {
  const classCollection = query(collection(db, 'Class'), orderBy('name', 'asc'));
  const querySnapshot = await getDocs(classCollection);
  let classArray: GrowingClass[] = [];
  querySnapshot.forEach((doc) => {
    const classData = doc.data() as GrowingClass;
    classArray.push({ ...classData, id: doc.id });
  });
  return classArray;
}
