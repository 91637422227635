import useLoadingIndicatorStore from 'stores/useLoadingIndicatorStore';
import loadingLottie from '../../../assets/lottie/loading.json';

import * as S from './LoadingIndicator.styled';
import { createPortal } from 'react-dom';

export default function LoadingIndicator() {
  const { loadingStates } = useLoadingIndicatorStore();

  const isShow = loadingStates.length > 0;

  if (!isShow) {
    return null;
  }
  return createPortal(
    <S.Container>
      <S.Indicator animationData={loadingLottie} loop autoPlay />
    </S.Container>,
    document.body,
  );
}
