import { useEffect } from 'react';
import useLoadingIndicatorStore, {
  LoadingIndicatorShowProps,
} from 'stores/useLoadingIndicatorStore';

export default function useLoadingIndicator({ id, isShow }: LoadingIndicatorShowProps) {
  const { setLoadingStates, removeLoadingStates } = useLoadingIndicatorStore();

  useEffect(() => {
    if (isShow) {
      setLoadingStates({ id, isShow });
    } else {
      removeLoadingStates(id);
    }
    document.body.style.overflow = isShow ? 'hidden' : 'unset';
  }, [id, isShow, setLoadingStates, removeLoadingStates]);
}
