import { useState } from 'react';
import { deleteStudentPreset } from '.';
import { useSelector, RootStateOrAny } from 'react-redux';

export const useDeleteStudentPresets = () => {
  const [isLoading, setIsLoading] = useState(false);

  const userUid = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo
    .uid as string;

  const deleteStudentPresets = async (studentPresetId: string) => {
    setIsLoading(true);

    try {
      await deleteStudentPreset(userUid, studentPresetId);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, mutation: deleteStudentPresets };
};
