import db from '../../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { MemberInfo } from 'types';

export async function getStudents() {
  const userCollection = query(collection(db, 'Users'), orderBy('name', 'asc'));

  const querySnapshot = await getDocs(userCollection);

  let studentsArray: MemberInfo[] = [];
  querySnapshot.forEach((doc) => {
    const userData = doc.data() as MemberInfo;
    if (!userData.teacher && !!userData.state) {
      studentsArray.push({ ...userData, id: doc.id });
    }
  });
  return studentsArray;
}

export async function getTeachers() {
  const teacherCollection = query(collection(db, 'Users'), orderBy('name', 'asc'));

  const querySnapshot = await getDocs(teacherCollection);

  let teachersArray: MemberInfo[] = [];
  querySnapshot.forEach((doc) => {
    const userData = doc.data() as MemberInfo;
    if (userData.teacher && !!userData.state) {
      teachersArray.push({ ...userData, id: doc.id });
    }
  });
  return teachersArray;
}
