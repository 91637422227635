import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputContainer = styled.div<{
  disabled: boolean;
  isFocused: boolean;
  isError: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  border-radius: 8px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.white};

  min-height: 54px;

  ${({ theme, disabled, isFocused, isError }) => {
    if (disabled) {
      return css`
        background-color: ${theme.colors.gray6};
      `;
    }
    if (isFocused) {
      return css`
        border-color: ${theme.colors.blue1};
      `;
    }
    if (isError) {
      return css`
        border-color: ${theme.colors.red};
      `;
    }
  }}
`;

export const Label = styled.label`
  ${({ theme }) => theme.typography.Text1}
  color: ${({ theme }) => theme.colors.gray1};

  text-align: left;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.gray1};
  ${({ theme }) => theme.typography.Text2}

  border: none;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray3};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Message = styled.span<{ isError?: boolean }>`
  position: absolute;
  left: 16px;
  top: calc(100% + 8px);
  color: ${({ theme, isError }) => (isError ? theme.colors.red : theme.colors.black)};
  ${({ theme }) => theme.typography.Caption1}

  text-align: left;
`;
