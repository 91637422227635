import usePortal from 'components/hooks/usePortal';
import useOffcanvas from './useOffcanvas';

export const OffcanvasProvider = () => {
  const Portal = usePortal('offcanvas-root');

  const { offcanvas } = useOffcanvas();

  if (!offcanvas) return null;

  return <Portal>{offcanvas}</Portal>;
};
