import { create } from 'zustand';

export interface LoadingIndicatorShowProps {
  id: string;
  isShow: boolean;
}

interface UseLoadingIndicatorStoreStates {
  loadingStates: LoadingIndicatorShowProps[];
  setLoadingStates: (isLoadingIndicatorShow: LoadingIndicatorShowProps) => void;
  removeLoadingStates: (id: string) => void;
}

const useLoadingIndicatorStore = create<UseLoadingIndicatorStoreStates>((set) => ({
  loadingStates: [],

  setLoadingStates: (loadingState: LoadingIndicatorShowProps) => {
    set((state) => ({ ...state, loadingStates: [...state.loadingStates, loadingState] }));
  },

  removeLoadingStates: (id: string) => {
    set((state) => ({
      ...state,
      loadingStates: state.loadingStates.filter((loadingState) => loadingState.id !== id),
    }));
  },
}));

export default useLoadingIndicatorStore;
