import { BUTTON_VARIANT } from 'components/atoms/buttons/button/Button.constants';
import { TitledInputText } from 'components/blocks';
import { Dialog } from 'components/molecules';
import * as S from './SavePresetsPopup.styled';
import { useState } from 'react';
import { usePostStudentPresets } from 'api/student-preset/usePostStudentPresets';
import { ScoreSetProps } from '../../ScoreSection';
import { MemberInfo } from 'types';
import { nanoid } from 'nanoid';
import useLoadingIndicator from 'hooks/useLoadingIndicator';

interface SavePresetsPopupProps {
  onClose: () => void;
  scoreSet: ScoreSetProps[];
  students?: MemberInfo[];
}

export const SavePresetsPopup = ({ onClose, scoreSet, students }: SavePresetsPopupProps) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isOnceClicked, setIsOnceClicked] = useState(false);

  const { mutate: postStudentPresets, isLoading: isPostStudentPresetsLoading } =
    usePostStudentPresets();

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleTitleDelete = () => {
    setTitle('');
  };

  const handleDescriptionDelete = () => {
    setDescription('');
  };

  const handleSave = async () => {
    setIsOnceClicked(true);
    if (!title) return;
    try {
      await postStudentPresets({
        studentPreset: {
          id: nanoid(),
          title,
          comment: description,
          studentList: scoreSet.map(({ studentId }) => ({
            name: students?.find(({ id }) => id === studentId)?.name ?? '',
            sid: studentId,
          })),
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const isValidTitle = title.length > 0;
  const isTitleError = isOnceClicked && !isValidTitle;

  useLoadingIndicator({ id: 'save-presets-popup', isShow: isPostStudentPresetsLoading });

  return (
    <Dialog
      title="입력한 학생 정보를 저장합니다."
      onClose={onClose}
      children={
        <S.Container>
          <TitledInputText
            label="제목"
            placeholder="제목을 입력하세요."
            value={title}
            onChange={handleTitleChange}
            isError={isTitleError}
            helperText="제목을 입력해주세요."
            errorText="제목을 입력해주세요."
            onDelete={handleTitleDelete}
          />
          <TitledInputText
            label="설명"
            placeholder="설명을 입력하세요."
            value={description}
            onChange={handleDescriptionChange}
            onDelete={handleDescriptionDelete}
          />
        </S.Container>
      }
      buttons={[
        {
          children: '확인',
          variant: BUTTON_VARIANT.PRIMARY,
          onClick: handleSave,
          disabled: isTitleError || isPostStudentPresetsLoading,
        },
      ]}
    />
  );
};
