import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const opacityOpenTransition = keyframes`
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const opacityCloseTransition = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0.3);
  }
  to {
    background-color: transparent;
  }
`;

const dialogOpenTransition = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const dialogCloseTransition = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
`;

export const Backdrop = styled.div<{ isClosed: boolean; closeTime: number }>`
  position: fixed;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: ${({ isClosed, closeTime: onCloseTime }) =>
    isClosed
      ? css`
          ${opacityCloseTransition} ${onCloseTime}ms ease-in-out forwards
        `
      : css`
          ${opacityOpenTransition} ${onCloseTime}ms ease-in-out forwards
        `};
`;

export const Container = styled.div<{ isClosed: boolean; closeTime: number }>`
  z-index: 10001;
  min-width: 400px;

  animation: ${({ isClosed, closeTime: onCloseTime }) =>
    isClosed
      ? css`
          ${dialogCloseTransition} ${onCloseTime}ms ease-in-out forwards
        `
      : css`
          ${dialogOpenTransition} ${onCloseTime}ms ease-in-out forwards
        `};

  & > .part {
    padding-left: 36px;
    padding-right: 36px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  & > .part:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  & > .part:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;

  padding-top: 12px;
  padding-bottom: 12px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  white-space: pre-wrap;
  word-break: break-word;

  padding-top: 24px;
  padding-bottom: 24px;
`;

export const Title = styled.h2`
  margin: 0;
  ${({ theme }) => theme.typography.Title1};
`;

export const Description = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.Body1};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
`;
