import { GrowingConfig } from 'types/config';
import db from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

export async function getConfig() {
  const querySnapshot = await getDoc(doc(db, 'Setting', '만덕'));

  const config = querySnapshot.data() as GrowingConfig;

  return config;
}
