import { Dialog } from 'components/molecules';

interface SaveScoreSuccessPopupProps {
  onClose: () => void;
}

export const SaveScoreSuccessPopup = ({ onClose }: SaveScoreSuccessPopupProps) => {
  return (
    <Dialog
      title="입력한 점수가 저장되었습니다."
      onClose={onClose}
      buttons={[{ children: '확인', onClick: onClose }]}
    />
  );
};
