import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  padding-top: 24px;
  padding-bottom: 32px;
`;
