import { useEffect } from 'react';
import * as S from './Dialog.styled';
import useDialog from './useDialog';
import { Button } from 'components/atoms';

interface DialogProps {
  title?: string;
  description?: string;
  onClose: () => void;
  closeTime?: number;
  buttons?: React.ComponentProps<typeof Button>[];
  children?: React.ReactNode;
}

export const Dialog = ({
  title,
  description,
  onClose,
  closeTime = 300,
  buttons,
  children,
}: DialogProps) => {
  const { isClosed = false, hide } = useDialog();

  useEffect(() => {
    const handleClose = async () => {
      await new Promise((resolve) => setTimeout(resolve, closeTime));
      hide();
    };

    if (isClosed) {
      handleClose();
    }
  }, [isClosed, hide, closeTime]);

  return (
    <S.Backdrop isClosed={isClosed} closeTime={closeTime} onClick={onClose}>
      <S.Container isClosed={isClosed} closeTime={closeTime} onClick={(e) => e.stopPropagation()}>
        <S.Header className="part" />
        {title || description ? (
          <S.Content className="part">
            {title && <S.Title>{title}</S.Title>}
            {description && <S.Description>{description}</S.Description>}
          </S.Content>
        ) : null}
        <div className="part">{children}</div>
        {buttons?.length ? (
          <S.Footer className="part">
            {buttons.map((button) => (
              <Button key={button.children?.toString()} {...button} />
            ))}
          </S.Footer>
        ) : null}
      </S.Container>
    </S.Backdrop>
  );
};
