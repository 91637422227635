export const BUTTON_SIZE = {
  SM: 'SM',
  MD: 'MD',
  LG: 'LG',
} as const;

export const BUTTON_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  OUTLINE: 'outline',
  DANGER: 'danger',
  DANGER_OUTLINE: 'danger-outline',
  GHOST: 'ghost',
} as const;
