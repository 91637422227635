import { create } from 'zustand';

interface UseOffcanvasState {
  isClosed?: boolean;
  offcanvas: React.ReactNode;
}

interface UseOffcanvasStoreState extends UseOffcanvasState {
  open: ({ offcanvas }: UseOffcanvasState) => void;
  close: () => void;
  hide: () => void;
}

const useOffcanvas = create<UseOffcanvasStoreState>((set) => ({
  isClosed: false,
  offcanvas: null,
  open: ({ offcanvas }: UseOffcanvasState) => {
    set(() => {
      return {
        offcanvas,
        isClosed: false,
      };
    });
  },
  close: () => {
    set(() => {
      return {
        isClosed: true,
      };
    });
  },
  hide: () => {
    set(() => {
      return {
        offcanvas: null,
      };
    });
  },
}));

const offcanvasState = useOffcanvas.getState();

export const OffcanvasService = {
  open: offcanvasState.open,
  close: offcanvasState.close,
};

export default useOffcanvas;
