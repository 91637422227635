import { Colors, OpacityColors } from './colors';
import { ELEVATION } from './elevation';
import { TYPOGRAPHY } from './typography';

export const theme = {
  colors: Colors,
  opacityColors: OpacityColors,
  elevation: ELEVATION,
  typography: TYPOGRAPHY,
};

export type Color = typeof Colors;
export type OpacityColor = typeof OpacityColors;
export type Elevation = typeof ELEVATION;
export type Typography = typeof TYPOGRAPHY;

declare module '@emotion/react' {
  export interface Theme {
    colors: Color;
    opacityColors: OpacityColor;
    elevation: Elevation;
    typography: Typography;
  }
}
