import styled from '@emotion/styled';
import Lottie from 'lottie-react';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  justify-content: center;
  align-items: center;
  inset: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const Indicator = styled(Lottie)`
  width: 128px;
  height: 128px;
`;
