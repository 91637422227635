import { useCallback, useEffect, useState } from 'react';
import { StudentPreset } from 'types';
import { getStudentPresets } from '.';
import { RootStateOrAny, useSelector } from 'react-redux';

export const useGetStudentPresets = () => {
  const [studentPresets, setStudentPresets] = useState<StudentPreset[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const userUid = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo
    .uid as string;

  const getStudentPresetsInfo = useCallback(async () => {
    if (!userUid) {
      return;
    }

    setIsLoading(true);

    try {
      const studentPresets = await getStudentPresets(userUid);
      setStudentPresets(studentPresets);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [userUid]);

  useEffect(() => {
    getStudentPresetsInfo();
  }, [getStudentPresetsInfo]);

  return { data: studentPresets, isLoading, refetch: getStudentPresetsInfo };
};
