import { useState } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { PostTestScoreRequest } from 'types';
import { postTestScores } from '.';

interface PostTestScoreArgs {
  request: Omit<PostTestScoreRequest, 'tid' | 'log' | 'office'>;
}

export const usePostTestScore = () => {
  const [isLoading, setIsLoading] = useState(false);

  const userUid = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo
    .uid as string;

  const postTestScore = async ({ request }: PostTestScoreArgs) => {
    if (!userUid) {
      return;
    }

    setIsLoading(true);

    try {
      await postTestScores({ ...request, tid: userUid, log: new Date(), office: '만덕' });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, mutate: postTestScore };
};
