import styled from '@emotion/styled';

export const Radio = styled.input`
  appearance: none;
  border: ${({ theme }) => theme.colors.gray5} 5px solid;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  width: 20px;
  height: 20px;

  transition: all 0.05s ease-in-out;

  cursor: pointer;

  &:hover {
    border: ${({ theme }) => theme.colors.blue2} 5px solid;
  }

  &:active {
    border: ${({ theme }) => theme.colors.blue1} 5px solid;
  }

  &:checked {
    border: ${({ theme }) => theme.colors.blue1} 5px solid;
  }
`;
