import { useState } from 'react';
import * as S from 'components/templates/common-container/CommonContainer.styled';
import BasicLayout from 'components/templates/basic-layout';
import { OptionSection } from './_section/option-section';
import ScoreSection from './_section/score-section/ScoreSection';
import { SubjectKey } from 'types/subject';
import { SUBJECT } from 'constants/subject';

function RefactoredTestInput() {
  const [scoredClass, setScoredClass] = useState<string | undefined>();
  const handleScoredClass = (value?: string) => {
    if (!value) {
      setScoredClass(undefined);
    } else {
      setScoredClass(value);
    }
  };

  const [subject, setSubject] = useState<SubjectKey | undefined>();
  const handleSubject = (value?: string) => {
    if (!value) {
      setSubject(undefined);
    } else if (Object.keys(SUBJECT).includes(value)) {
      setSubject(value as SubjectKey);
    }
  };

  const [date, setDate] = useState<Date | null>(new Date());
  const handleDateChange = (date: Date) => {
    setDate(date);
  };

  return (
    <BasicLayout headerType="teacher" headerText="점수 입력">
      <S.Container>
        <S.RowContentContainer>
          <OptionSection
            scoredClass={scoredClass}
            handleScoredClass={handleScoredClass}
            subject={subject}
            handleSubject={handleSubject}
            date={date}
            handleDateChange={handleDateChange}
          />
          <ScoreSection scoredClass={scoredClass} subject={subject} date={date} />
        </S.RowContentContainer>
      </S.Container>
    </BasicLayout>
  );
}
export default RefactoredTestInput;
