import { create } from 'zustand';

interface UseDialogState {
  isClosed?: boolean;
  dialog: React.ReactNode;
}

interface UseDialogStoreState extends UseDialogState {
  open: ({ dialog }: UseDialogState) => void;
  close: () => void;
  hide: () => void;
}

const useDialog = create<UseDialogStoreState>((set) => ({
  isClosed: false,
  dialog: null,
  open: ({ dialog }: UseDialogState) => {
    set(() => {
      return { dialog, isClosed: false };
    });
  },
  close: () => {
    set(() => {
      return { isClosed: true };
    });
  },
  hide: () => {
    set(() => {
      return { dialog: null };
    });
  },
}));

const dialogState = useDialog.getState();

export const DialogService = {
  open: dialogState.open,
  close: dialogState.close,
};

export default useDialog;
