import styled from '@emotion/styled';
import { Colors, OpacityColors } from 'styles/colors';
import { Button1 } from 'styles/typography';
import { ButtonVariant } from './Button.types';

const buttonStyles = (variant: ButtonVariant) => {
  switch (variant) {
    case 'secondary':
      return {
        color: Colors.black,
        border: `2px solid ${Colors.blue1}`,
        background: Colors.white,

        disabledColor: OpacityColors.blackOpacity20,
        disabledBorder: `2px solid ${OpacityColors.blue1Opacity20}`,
        disabledBackground: OpacityColors.whiteOpacity20,

        hoverColor: Colors.white,
        hoverBackground: Colors.blue1,
      };
    case 'outline':
      return {
        color: Colors.black,
        border: `2px solid ${Colors.gray4}`,
        background: Colors.white,

        disabledColor: OpacityColors.blackOpacity20,
        disabledBorder: `2px solid ${OpacityColors.gray4Opacity20}`,
        disabledBackground: OpacityColors.whiteOpacity20,

        hoverColor: Colors.black,
        hoverBackground: Colors.gray4,
      };
    case 'danger':
      return {
        color: Colors.white,
        background: Colors.red,

        disabledColor: OpacityColors.whiteOpacity20,
        disabledBackground: OpacityColors.redOpacity20,

        hoverColor: Colors.white,
        hoverBackground: Colors.red,
      };
    case 'danger-outline':
      return {
        color: Colors.red,
        border: `2px solid ${Colors.red}`,
        background: Colors.white,

        disabledColor: OpacityColors.redOpacity20,
        disabledBorder: `2px solid ${OpacityColors.redOpacity20}`,
        disabledBackground: OpacityColors.whiteOpacity20,

        hoverColor: Colors.white,
        hoverBackground: Colors.red,
      };
    case 'ghost':
      return {
        color: Colors.black,
        background: Colors.white,

        disabledColor: OpacityColors.blackOpacity20,

        hoverColor: Colors.gray2,
      };
    case 'primary':
    default:
      return {
        color: Colors.white,
        background: Colors.blue1,

        disabledColor: Colors.white,
        disabledBackground: OpacityColors.blue1Opacity20,

        hoverColor: Colors.white,
        hoverBackground: Colors.blue3,
      };
  }
};

export const Container = styled.button<{
  variant: ButtonVariant;
  disabled?: boolean;
  fit?: 'content' | 'full';
}>`
  width: ${({ fit }) => (fit === 'content' ? 'fit-content' : '100%')};

  border: ${({ variant }) => buttonStyles(variant).border ?? 'none'};
  border-radius: 8px;

  padding: ${({ variant }) => (variant === 'primary' || variant === 'danger' ? '14px' : '12px')};

  background-color: ${({ variant }) => buttonStyles(variant).background};
  color: ${({ variant }) => buttonStyles(variant).color};
  ${Button1};

  &:disabled {
    background-color: ${({ variant }) => buttonStyles(variant).disabledBackground};
    border: ${({ variant }) => buttonStyles(variant).disabledBorder ?? 'none'};
    color: ${({ variant }) => buttonStyles(variant).disabledColor};
  }
  &:hover {
    ${({ variant, disabled }) =>
      disabled
        ? ''
        : `
        background-color: ${buttonStyles(variant).hoverBackground};
        color: ${buttonStyles(variant).hoverColor};
    `}
  }
  &:active {
    ${({ variant, disabled }) =>
      disabled
        ? ''
        : `
        background-color: ${buttonStyles(variant).hoverBackground};
        color: ${buttonStyles(variant).hoverColor};
    `}
  }
`;
