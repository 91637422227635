export const Elevation = {
  shadow2: 'box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
  shadow4: 'box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  shadow8: 'box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25)',
  shadow16: 'box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.25)',
  shadow24: 'box-shadow: 0px 24px 24px 0px rgba(0, 0, 0, 0.25)',
};

export const ELEVATION = {
  '2': '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
  '4': '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  '8': '0px 8px 8px 0px rgba(0, 0, 0, 0.25)',
  '16': '0px 16px 16px 0px rgba(0, 0, 0, 0.25)',
  '24': '0px 24px 24px 0px rgba(0, 0, 0, 0.25)',
};
