import * as S from './ScoreSection.styled';
import { useEffect, useState } from 'react';
import useGetStudents from 'api/student/useGetStudents';
import { nanoid } from 'nanoid';
import { Dropdown, DropdownOption, TitledInputText } from 'components/blocks';
import { Button, Icon } from 'components/atoms';
import useGetConfig from 'api/config/useGetConfig';
import { SubjectKey } from 'types/subject';
import { BUTTON_VARIANT } from 'components/atoms/buttons/button/Button.constants';
import { DialogService, OffcanvasService } from 'components/molecules';
import { ClearScoreSetPopup, LoadPresetsPopup, SavePresetsPopup } from './_components';
import { StudentPreset } from 'types';
import { usePostTestScore } from 'api/test-score/usePostTestScore';
import { toast } from 'react-toastify';
import { SaveScoreSuccessPopup } from './_components/save-score-success-popup';
import useLoadingIndicator from 'hooks/useLoadingIndicator';
import { SUBJECT } from 'constants/subject';

interface ScoreSectionProps {
  scoredClass?: string;
  subject?: SubjectKey;
  date: Date | null;
}

export interface ScoreSetProps {
  id: string;
  studentId: string;
  score: number;
}

export default function ScoreSection({ scoredClass, subject, date }: ScoreSectionProps) {
  const { data: students, isLoading: isStudentsLoading } = useGetStudents();
  const { data: config, isLoading: isConfigLoading } = useGetConfig();
  const { mutate: postTestScore, isLoading: isPostTestScoreLoading } = usePostTestScore();

  const isLoading = isStudentsLoading || isConfigLoading || isPostTestScoreLoading;

  const maxScore = subject ? config?.maxScore[subject] : undefined;

  const [studentOptions, setStudentOptions] = useState<DropdownOption[]>();

  const [scoreSet, setScoreSet] = useState<ScoreSetProps[]>([
    { id: nanoid(), studentId: '', score: 0 },
  ]);

  const handleAddScore = () => {
    setScoreSet((prev) => [...prev, { id: nanoid(), studentId: '', score: 0 }]);
  };

  const handleStudentChange = (id: string, studentId: string) => {
    setScoreSet((prev) => {
      const newScoreSet = [...prev];
      newScoreSet[newScoreSet.findIndex((score) => score.id === id)].studentId = studentId;
      return newScoreSet;
    });
  };

  const handleScoreChange = (id: string, score: string) => {
    const regex = /^[0-9]*$/;
    if (!regex.test(score.toString())) {
      return;
    }
    setScoreSet((prev) => {
      const newScoreSet = [...prev];
      newScoreSet[newScoreSet.findIndex((score) => score.id === id)].score = Number(score);
      return newScoreSet;
    });
  };

  const handleRemoveScoreSet = (id: string) => {
    setScoreSet((prev) => {
      const newScoreSet = [...prev];
      return newScoreSet.filter((score) => score.id !== id);
    });
  };

  const handleLoadStudentsClosed = () => {
    OffcanvasService.close();
  };

  const handleSaveStudentsClosed = () => {
    DialogService.close();
  };

  const handleSaveScoreSuccessClosed = () => {
    DialogService.close();
  };

  const handleClearScoreSetClosed = () => {
    DialogService.close();
  };

  const handleClearScoreSet = () => {
    setScoreSet([]);
    handleClearScoreSetClosed();
  };

  const handleOpenLoadStudentPresets = () => {
    OffcanvasService.open({
      offcanvas: (
        <LoadPresetsPopup
          onClose={handleLoadStudentsClosed}
          onLoadStudentsFromPresets={handleLoadStudentsFromPresets}
        />
      ),
    });
  };

  const handleOpenSaveStudentPresets = () => {
    DialogService.open({
      dialog: (
        <SavePresetsPopup
          onClose={handleSaveStudentsClosed}
          scoreSet={scoreSet}
          students={students}
        />
      ),
    });
  };

  const handleOpenClearScoreSet = () => {
    DialogService.open({
      dialog: (
        <ClearScoreSetPopup onClose={handleClearScoreSetClosed} onClear={handleClearScoreSet} />
      ),
    });
  };

  const handleLoadStudentsFromPresets = (preset: StudentPreset) => {
    setScoreSet(preset.studentList.map(({ sid }) => ({ id: nanoid(), studentId: sid, score: 0 })));
  };

  const handleSubmit = async () => {
    if (!scoredClass || !subject || !date) {
      return;
    }

    try {
      await postTestScore({
        request: {
          scores: scoreSet.map(({ studentId, score }) => ({
            sid: studentId,
            name: students?.find(({ id }) => id === studentId)?.name ?? '',
            score,
          })),
          cid: scoredClass,
          date,
          subject: SUBJECT[subject],
        },
      });

      DialogService.open({
        dialog: <SaveScoreSuccessPopup onClose={handleSaveScoreSuccessClosed} />,
      });
    } catch (e) {
      toast.error('점수 입력에 실패했습니다.');
    }
  };

  const isButtonDisabled =
    !scoredClass ||
    !subject ||
    !date ||
    scoreSet.length === 0 ||
    scoreSet.some(({ studentId }) => !studentId) ||
    scoreSet.some(({ score }) => !!score && !!maxScore && score > maxScore);

  useLoadingIndicator({
    id: 'score-section-submit',
    isShow: isLoading,
  });

  useEffect(() => {
    if (!students) {
      return;
    }
    setStudentOptions(students.map(({ id, name }) => ({ label: name, value: id })));
  }, [students]);

  return (
    <S.Container>
      <S.StudentsButtonContainer>
        <S.StudentsPresetButtonContainer>
          <Button
            fit="content"
            variant={BUTTON_VARIANT.GHOST}
            onClick={handleOpenLoadStudentPresets}
          >
            학생 정보 불러오기↗
          </Button>
          <Button
            fit="content"
            variant={BUTTON_VARIANT.GHOST}
            onClick={handleOpenSaveStudentPresets}
            disabled={scoreSet.length === 0 || !!scoreSet.find(({ studentId }) => !studentId)}
          >
            학생 정보 저장하기↗
          </Button>
        </S.StudentsPresetButtonContainer>
        <Button fit="content" variant={BUTTON_VARIANT.GHOST} onClick={handleOpenClearScoreSet}>
          전체 삭제
        </Button>
      </S.StudentsButtonContainer>
      <S.ScoreContainer>
        {scoreSet.map(({ id, studentId, score }) => (
          <S.ScoreSetContainer key={id}>
            <Dropdown
              label="학생"
              value={studentId}
              handleChange={(studentId) => handleStudentChange(id, studentId ?? '')}
              options={studentOptions}
              placeholder="학생을 선택하세요."
              disabled={isStudentsLoading}
            />
            <TitledInputText
              label="점수"
              value={score.toString()}
              placeholder="점수를 입력하세요."
              onChange={(e) => handleScoreChange(id, e.target.value)}
              onDelete={() => handleScoreChange(id, '0')}
              isError={!!score && !!maxScore && score > maxScore}
              errorText={`점수는 ${maxScore}점을 초과할 수 없습니다.`}
              disabled={isConfigLoading}
              max={maxScore}
            />
            <S.RemoveScoreButton onClick={() => handleRemoveScoreSet(id)}>
              <Icon icon="TrashIcon" />
            </S.RemoveScoreButton>
          </S.ScoreSetContainer>
        ))}
        <S.AddScoreButton onClick={handleAddScore}>
          <Icon icon="PlusIcon" />
        </S.AddScoreButton>
        <Button disabled={isButtonDisabled} onClick={handleSubmit}>
          점수 입력 완료
        </Button>
      </S.ScoreContainer>
    </S.Container>
  );
}
