import db from '../../firebase';
import { collection, addDoc, doc, setDoc, increment } from 'firebase/firestore';
import { PostTestScoreRequest } from 'types';

export async function postTestScores(request: PostTestScoreRequest) {
  const dateId =
    new Date().getFullYear().toString().slice(-2) + ('0' + (new Date().getMonth() + 1)).slice(-2);

  const attendId =
    request.date.getFullYear().toString() +
    (request.date.getMonth() + 1).toString() +
    request.date.getDate().toString();

  const testScoreCollection = collection(db, 'TestScore');

  const subjectLabel = () => {
    switch (request.subject) {
      case 'Listening':
        return 'lis';
      case 'Reading':
        return 'rea';
      case 'Grammar':
        return 'gra';
      case '모의고사':
        return 'mock';
      default:
        throw new Error('Invalid subject');
    }
  };

  request.scores.forEach(async ({ sid, name, score }) => {
    const testScoreStatDoc = doc(db, 'Users', sid, 'testScoreStat', dateId);
    const attendDoc = doc(db, 'Users', sid, 'attend', attendId);

    const { cid, date, subject, tid, office, log } = request;

    await setDoc(
      testScoreStatDoc,
      {
        [`${subjectLabel()}sum`]: increment(score),
        [`${subjectLabel()}count`]: increment(1),
      },
      { merge: true },
    );

    await setDoc(attendDoc, {
      year: request.date.getFullYear(),
      month: request.date.getMonth() + 1,
      date: new Date(request.date.toDateString()),
      attend: true,
      sid: sid,
    });

    await addDoc(testScoreCollection, {
      cid,
      date,
      subject,
      tid,
      office,
      log,
      sid,
      name,
      score,
    });
  });
}
