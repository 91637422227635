import styled from '@emotion/styled';
import { Colors } from 'styles/colors';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.label`
  ${({ theme }) => theme.typography.Text1}
  color: ${({ theme }) => theme.colors.gray1};

  text-align: left;
`;

export const DateInput = styled.div<{ showPlaceholder?: boolean }>`
  padding: 0 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  border-radius: 8px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white};
  min-height: 54px;
  color: ${({ theme, showPlaceholder }) =>
    showPlaceholder ? theme.colors.gray3 : theme.colors.gray1};
  ${({ theme }) => theme.typography.Text2}
  display: flex;
  align-items: center;
`;

export const Calendar = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 320px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.elevation[2]};
  padding: 16px;
  z-index: 10;
`;

export const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  & > h2 {
    ${({ theme }) => theme.typography.Title2}
    margin: 0;
    color: ${({ theme }) => theme.colors.gray2};
  }
`;

export const NavButton = styled.button`
  border: none;
  background: none;
  padding: 4px 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray2};
  font-size: 16px;

  &:hover {
    color: ${({ theme }) => theme.colors.gray1};
  }
`;

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  text-align: center;
`;

export const Weekday = styled.div`
  ${({ theme }) => theme.typography.Body2}
  color: ${({ theme }) => theme.colors.gray3};
  padding: 4px 0;
`;

export const Day = styled.div<{ isToday?: boolean; isSelected?: boolean; disabled?: boolean }>`
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.typography.Body2}
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  border-radius: 50%;
  color: ${({ theme, isToday, isSelected, disabled }) => {
    if (isSelected) return theme.colors.white;
    if (isToday) return theme.colors.blue2;
    if (disabled) return theme.colors.gray4;
    return theme.colors.gray2;
  }};
  text-decoration: ${({ isToday, isSelected }) => (isToday && !isSelected ? 'underline' : 'none')};

  background-color: ${({ isSelected }) => (isSelected ? Colors.blue1 : 'transparent')};

  &:hover {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray4 : theme.colors.blue1)};
    background-color: ${({ theme, disabled }) => (disabled ? 'transparent' : theme.colors.gray6)};
  }
`;
