import { StudentPreset } from 'types';
import db from '../../firebase';
import { addDoc, collection, deleteDoc, doc, getDocs, query } from 'firebase/firestore';

export async function getStudentPresets(userId: string) {
  const studentPresetCollection = query(collection(db, 'Users', userId, 'studentSetting'));

  const querySnapshot = await getDocs(studentPresetCollection);

  let studentPresets: StudentPreset[] = [];
  querySnapshot.forEach((doc) => {
    const studentPresetData = doc.data() as StudentPreset;
    studentPresets.push({ ...studentPresetData, id: doc.id });
  });

  return studentPresets;
}

export async function postStudentPreset(userId: string, studentPreset: StudentPreset) {
  const studentPresetCollection = collection(db, 'Users', userId, 'studentSetting');

  await addDoc(studentPresetCollection, studentPreset);
}

export async function deleteStudentPreset(userId: string, studentPresetId: string) {
  const studentPresetCollection = collection(db, 'Users', userId, 'studentSetting');

  await deleteDoc(doc(studentPresetCollection, studentPresetId));
}
