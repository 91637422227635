import { useState } from 'react';

import { useCallback } from 'react';

import { useEffect } from 'react';
import { getConfig } from '.';
import { GrowingConfig } from 'types/config';

export default function useGetConfig() {
  const [data, setData] = useState<GrowingConfig>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getConfigInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const config = await getConfig();
      setData(config);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getConfigInfo();
  }, [getConfigInfo]);

  return { data, isLoading };
}
