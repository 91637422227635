import { DatePicker, Dropdown } from 'components/blocks/forms';
import * as S from './OptionSection.styled';
import { useEffect, useState } from 'react';
import useGetGrowingClasses from 'api/class/useGetGrowingClasses';
import { DropdownOption } from 'components/blocks/forms/dropdown/Dropdown';
import { SUBJECT } from 'constants/subject';
import { SubjectKey } from 'types/subject';

interface OptionSectionProps {
  scoredClass?: string;
  handleScoredClass: (value?: string) => void;
  subject?: SubjectKey;
  handleSubject: (value?: string) => void;
  date: Date | null;
  handleDateChange: (date: Date) => void;
}

export default function OptionSection({
  scoredClass,
  handleScoredClass,
  subject,
  handleSubject,
  date,
  handleDateChange,
}: OptionSectionProps) {
  const { data: growingClasses, isLoading: isGrowingClassesLoading } = useGetGrowingClasses();

  const [classOptions, setClassOptions] = useState<DropdownOption[]>();

  const subjectOptions: DropdownOption[] = Object.entries(SUBJECT).map(([key, korean]) => ({
    label: korean,
    value: key,
  }));

  const isClassError = !!scoredClass && !growingClasses?.find(({ id }) => id === scoredClass);

  useEffect(() => {
    if (!growingClasses) {
      return;
    }
    setClassOptions(growingClasses.map(({ id, name }) => ({ label: name, value: id })));
  }, [growingClasses]);

  return (
    <S.Container>
      <Dropdown
        id="class"
        label="반"
        value={scoredClass}
        handleChange={handleScoredClass}
        options={classOptions}
        errorText="올바른 값을 입력하세요."
        placeholder="반을 선택하세요."
        isError={isClassError}
        disabled={isGrowingClassesLoading}
      />
      <Dropdown
        id="subject"
        label="과목"
        value={subject}
        handleChange={handleSubject}
        options={subjectOptions}
        errorText="올바른 값을 입력하세요."
        placeholder="과목을 선택하세요."
      />
      <DatePicker label="날짜" value={date} handleChange={handleDateChange} />
    </S.Container>
  );
}
