import { useState, useRef, useEffect } from 'react';
import * as S from './DatePicker.styled';

interface DatePickerProps {
  value: Date | null;
  handleChange: (date: Date) => void;
  label: string;
  placeholder?: string;
  maxDate?: Date;
  minDate?: Date;
}

export function DatePicker({
  value,
  handleChange,
  label,
  placeholder = '날짜를 선택하세요.',
  maxDate,
  minDate,
}: DatePickerProps) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const weekDays = ['일', '월', '화', '수', '목', '금', '토'];

  // Get previous month's days
  const prevMonthDays = [];
  const prevMonthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
  for (let i = firstDayOfMonth - 1; i >= 0; i--) {
    prevMonthDays.push(prevMonthLastDay - i);
  }

  // Get current month's days
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  // Get next month's days
  const nextMonthDays = [];
  const totalDaysShown = 42; // 6 rows * 7 days
  const remainingDays = totalDaysShown - (prevMonthDays.length + days.length);
  for (let i = 1; i <= remainingDays; i++) {
    nextMonthDays.push(i);
  }

  const handlePrevMonth = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const handleNextMonth = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const handleDateSelect = (day: number) => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    handleChange(newDate);
    setIsOpen(false);
  };

  const isToday = (day: number) => {
    const today = new Date();
    return (
      day === today.getDate() &&
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear()
    );
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <S.Container ref={datePickerRef}>
      <S.Label>{label}</S.Label>
      <S.DateInput onClick={() => setIsOpen(!isOpen)} showPlaceholder={!value}>
        {value ? formatDate(value) : placeholder}
      </S.DateInput>

      {isOpen && (
        <S.Calendar>
          <S.CalendarHeader>
            <S.NavButton onClick={handlePrevMonth}>&lt;</S.NavButton>
            <h2>
              {currentDate.getFullYear()}년 {currentDate.getMonth() + 1}월
            </h2>
            <S.NavButton onClick={handleNextMonth}>&gt;</S.NavButton>
          </S.CalendarHeader>

          <S.CalendarGrid>
            {weekDays.map((day) => (
              <S.Weekday key={day}>{day}</S.Weekday>
            ))}

            {prevMonthDays.map((day) => (
              <S.Day key={`prev-${day}`} disabled>
                {day}
              </S.Day>
            ))}

            {days.map((day) => (
              <S.Day
                key={`current-${day}`}
                isToday={isToday(day)}
                isSelected={
                  value?.getDate() === day &&
                  value?.getMonth() === currentDate.getMonth() &&
                  value?.getFullYear() === currentDate.getFullYear()
                }
                onClick={() => handleDateSelect(day)}
                disabled={
                  (maxDate &&
                    new Date(currentDate.getFullYear(), currentDate.getMonth(), day) > maxDate) ||
                  (minDate &&
                    new Date(currentDate.getFullYear(), currentDate.getMonth(), day) < minDate)
                }
              >
                {day}
              </S.Day>
            ))}

            {nextMonthDays.map((day) => (
              <S.Day key={`next-${day}`} disabled>
                {day}
              </S.Day>
            ))}
          </S.CalendarGrid>
        </S.Calendar>
      )}
    </S.Container>
  );
}
