import { useEffect } from 'react';
import { OFFCANVAS_DIRECTION } from './Offcanvas.constants';
import * as S from './Offcanvas.styled';
import { OffcanvasDirection } from './Offcanvas.types';
import useOffcanvas from './useOffcanvas';

interface OffcanvasProps {
  isBackdropClickable?: boolean;
  direction?: OffcanvasDirection;
  children: React.ReactNode;
  onClose?: () => void;
  closeTime?: number;
}

export const Offcanvas = ({
  isBackdropClickable = true,
  direction = OFFCANVAS_DIRECTION.LEFT,
  children,
  onClose,
  closeTime = 300,
}: OffcanvasProps) => {
  const { isClosed = false, hide } = useOffcanvas();

  useEffect(() => {
    const handleClose = async () => {
      await new Promise((resolve) => setTimeout(resolve, closeTime));
      hide();
    };

    if (isClosed) {
      handleClose();
    }
  }, [isClosed, hide, closeTime]);

  return (
    <S.Backdrop
      onClick={isBackdropClickable ? onClose : undefined}
      isClosed={isClosed}
      closeTime={closeTime}
    >
      <S.Container
        direction={direction}
        isClosed={isClosed}
        closeTime={closeTime}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </S.Container>
    </S.Backdrop>
  );
};
