import { Dialog } from 'components/molecules';

interface ConfirmLoadPopupProps {
  onClose: () => void;
  onLoad: () => void;
}

export const ConfirmLoadPopup = ({ onClose, onLoad }: ConfirmLoadPopupProps) => {
  return (
    <Dialog
      title="학생 정보를 불러오면 기존에 입력한 학생 목록은 지워집니다. 계속하시겠습니까?"
      onClose={onClose}
      buttons={[
        { children: '취소', variant: 'outline', onClick: onClose },
        { children: '불러오기', onClick: onLoad },
      ]}
    />
  );
};
