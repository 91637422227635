import { BUTTON_SIZE, BUTTON_VARIANT } from './Button.constants';
import * as S from './Button.styled';
import { ButtonSize, ButtonVariant } from './Button.types';

interface ButtonProps extends React.ComponentProps<'button'> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  fit?: 'content' | 'full';
}

export default function Button({
  children,
  variant = BUTTON_VARIANT.PRIMARY,
  size = BUTTON_SIZE.MD,
  fit = 'full',
  ...rest
}: ButtonProps) {
  return (
    <S.Container variant={variant} {...rest} fit={fit}>
      {children}
    </S.Container>
  );
}
