import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer, { rootSaga } from './redux/index';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { ThemeProvider } from '@emotion/react';
import { theme } from 'styles/theme';
import { OffcanvasProvider, DialogProvider } from 'components/molecules';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  // composeWithDevTools(applyMiddleware(logger, sagaMiddleware, ReduxThunk)),
  composeWithDevTools(applyMiddleware(sagaMiddleware, ReduxThunk)),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
        <OffcanvasProvider />
        <DialogProvider />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
