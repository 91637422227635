import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { MemberInfo } from 'types';
import { getStudents } from '.';

export default function useGetMemberInfo() {
  const [students, setStudents] = useState<MemberInfo[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userUid = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo
    .uid as string;

  const getStudentsInfo = useCallback(async () => {
    if (!userUid) {
      return;
    }

    setIsLoading(true);

    try {
      const students = await getStudents();
      setStudents(students);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, [userUid]);

  useEffect(() => {
    getStudentsInfo();
  }, [getStudentsInfo]);

  return { data: students, isLoading };
}
