import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px;
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 700px;
  width: 50vw;
  height: 100vh;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.typography.Title1}
  margin: 0 0 60px 0;
`;

export const NoStudentList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 200px;

  ${({ theme }) => theme.typography.Body1}
  color: ${({ theme }) => theme.colors.gray3};
`;

export const StudentListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & > .row:nth-of-type(1) {
    .cell {
      border-top: 1px solid ${({ theme }) => theme.colors.gray5};
    }
  }

  & > .row:nth-of-type(even) {
    .cell {
      background-color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const StudentListTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 0 48px;
`;

export const StudentListTitleRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StudentListRow = styled.div`
  display: flex;
  flex-direction: row;

  & > .cell:nth-of-type(1) {
    border-left: 1px solid ${({ theme }) => theme.colors.gray5};
  }
`;

export const StudentListCell = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  align-items: center;
  flex: 1;

  width: 100%;

  color: ${({ theme }) => theme.colors.black};
`;

export const StudentListTitleCell = styled(StudentListCell)`
  ${({ theme }) => theme.typography.Title2}
  background-color: ${({ theme }) => theme.colors.white};
`;

export const StudentListRowCell = styled(StudentListCell)`
  ${({ theme }) => theme.typography.Body2}
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  border-top: none;
  border-left: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StudentListRadioButtonLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 100%;
`;

export const RemoveStudentPresetButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 48px;

  gap: 4px;
`;
