import usePortal from 'components/hooks/usePortal';
import useDialog from './useDialog';

export const DialogProvider = () => {
  const Portal = usePortal('dialog-root');

  const { dialog } = useDialog();

  if (!dialog) return null;

  return <Portal>{dialog}</Portal>;
};
