import { forwardRef, useRef, useState } from 'react';
import * as S from './TitledInputText.styled';
import { DeleteButton, ErrorIcon } from 'components/atoms';
import { Colors } from 'styles/colors';

interface TitledInputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  label: string;
  errorText?: string;
  helperText?: string;
  completedText?: string;
  isError?: boolean;
  onDelete?: () => void;
}

const TitledInputText = forwardRef<HTMLInputElement, TitledInputTextProps>(
  (
    {
      value,
      label,
      disabled = false,
      isError = false,
      errorText = '',
      helperText = '',
      onDelete,
      defaultValue,
      placeholder,
      ...rest
    }: TitledInputTextProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const isShowError = isError && !isFocused;
    const isShowDeleteButton = !isShowError && value;

    const handleFocus = () => {
      if (disabled) {
        return;
      }
      inputRef.current?.focus();
      setIsFocused(true);
    };

    const handleBlur = () => {
      if (inputRef.current?.value) {
        return;
      }
      setIsFocused(false);
    };

    const handleClearInput = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (!onDelete) {
        return;
      }
      onDelete();
      inputRef.current?.focus();
    };

    const handleInvalid = (e: React.InvalidEvent<HTMLInputElement>) => {
      e.currentTarget.setCustomValidity('');
    };

    return (
      <S.Container>
        <S.Label htmlFor={label}>{label}</S.Label>
        <S.InputContainer disabled={disabled} isFocused={isFocused} isError={isError}>
          <S.Input
            value={value}
            disabled={disabled}
            ref={ref}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onInvalid={handleInvalid}
            placeholder={placeholder}
            {...rest}
          />
          {isShowDeleteButton && <DeleteButton onMouseDown={handleClearInput} tabIndex={-1} />}
          {isShowError && <ErrorIcon color={Colors.red} />}
        </S.InputContainer>
        {!isError && !!helperText && <S.Message>{helperText}</S.Message>}
        {isError && !!errorText && <S.Message isError>{errorText}</S.Message>}
      </S.Container>
    );
  },
);

export default TitledInputText;
